import { FaBell } from "react-icons/fa";
import FillBtn from "../Buttons/FillBtn";
import { FaTrashCan } from "react-icons/fa6";
import functions from "../../utils/functions";
import OutlineBtn from "../Buttons/OutlineBtn";
import { useNavigate } from "react-router-dom";
import NotificationTabs from "../Notifications";
import Images from "../../Helper/ImageConstants";
import { IoIosLock, IoMdPerson } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import InformationModal from "../Modals/InformationModal";
import React, { memo, useCallback, useState } from "react";
import { notificationTabLabels } from "../../utils/constants";
import { RiLogoutCircleLine, RiMenu2Line } from "react-icons/ri";
import { DELETE_ACCOUNT_URL, LOGOUT_URL } from "../../utils/constants";
import { deleteAccount, logout } from "../../Redux/features/User/userApi";
import {
  Nav,
  Navbar,
  Button,
  NavItem,
  NavbarBrand,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const Topbar = ({ toggleSidebar, setToggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { user, userNotifications, fcmToken } = useSelector(
    (state) => state.user
  );

  const handleChangePasswordClick = useCallback(() => {
    functions.isUser(user?.role)
      ? navigate("/user/changePassword")
      : navigate("/admin/changePassword");
  }, [navigate, user]);

  const handleProfileClick = useCallback(() => {
    functions.isUser(user?.role)
      ? navigate("/user/profile")
      : navigate("/admin/profile");
  }, [navigate, user]);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmClick = useCallback(() => {
    const data = {
      apiEndpoint: DELETE_ACCOUNT_URL,
    };
    dispatch(deleteAccount(data)).then((res) => {
      if (res.type === "deleteAccount/fulfilled") {
        navigate("/signIn");
      }
    });
  }, [dispatch, navigate]);

  const handleToggleDeleteModal = useCallback(() => {
    setShowDeleteModal(!showDeleteModal);
  }, [showDeleteModal]);

  const handleLogoutClick = useCallback(() => {
    const data = {
      apiEndpoint: LOGOUT_URL,
      requestData: JSON.stringify({ deviceToken: fcmToken }),
    };
    dispatch(logout(data)).then((res) => {
      if (res.type === "logout/fulfilled") {
        navigate("/signIn");
      }
    });
  }, [dispatch, fcmToken, navigate]);

  const toggleNotifications = useCallback(() => {
    setShowNotifications(!showNotifications);
  }, [showNotifications]);

  return (
    <>
      <Navbar className={`navbar shadow-sm transition px-3`}>
        <NavbarBrand>
          <Button
            className="btn cursor-pointer bg-transparent border-0 text-dark"
            onClick={() => setToggleSidebar(!toggleSidebar)}
          >
            <RiMenu2Line size={20} />
          </Button>
        </NavbarBrand>
        <Nav className="ml-auto d-flex align-items-center gap-4">
          <NavItem>
            <FaBell
              className="text-primary"
              onClick={toggleNotifications}
              size={20}
            />
          </NavItem>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0" nav>
              <div
                className="bgProperties rounded-circle shadow"
                style={{
                  backgroundImage:
                    user?.profileImage === null
                      ? `url(${Images.USER_DUMMY_IMG})`
                      : `url(${user?.profileImage})`,
                  width: "40px",
                  height: "40px",
                }}
              ></div>
            </DropdownToggle>
            <DropdownMenu className="px-2">
              <DropdownItem className="p-0">
                <div
                  className="d-flex gap-1 align-items-center w-100 p-1 cursorPointer text-custom-dark"
                  onClick={handleProfileClick}
                >
                  <span className="me-2 d-flex">
                    <IoMdPerson size={20} />
                  </span>
                  <p className="mb-0">{"Profile"}</p>
                </div>
              </DropdownItem>
              <DropdownItem className="p-0">
                <div
                  className="d-flex gap-1 align-items-center w-100 p-1 cursorPointer text-custom-dark"
                  onClick={handleChangePasswordClick}
                >
                  <span className="me-2 d-flex">
                    <IoIosLock size={20} />
                  </span>
                  <p className="mb-0">{"Change Password"}</p>
                </div>
              </DropdownItem>
              {functions.isUser(user?.role) && (
                <DropdownItem className="p-0">
                  <div
                    className="d-flex gap-1 align-items-center w-100 p-1 text-custom-dark"
                    onClick={handleDeleteClick}
                  >
                    <span className="me-2 d-flex">
                      <FaTrashCan size={16} className="mb-1" />
                    </span>
                    <p className="mb-0">{"Delete Account"}</p>
                  </div>
                </DropdownItem>
              )}

              <DropdownItem className="p-0">
                <div
                  className="d-flex gap-1 align-items-center w-100 p-1 cursorPointer text-custom-dark"
                  onClick={handleLogoutClick}
                >
                  <span className="me-2 d-flex">
                    <RiLogoutCircleLine size={16} />
                  </span>
                  <p className="mb-0">{"Logout"}</p>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <InformationModal
          isOpen={showDeleteModal}
          content={
            <p className="mb-0">
              {"Are you certain you want to delete your account?"}
            </p>
          }
          footer={
            <div className="d-flex justify-content-between w-100 gap-2">
              <FillBtn
                className="w-100 py-2"
                text={"Confirm"}
                handleOnClick={handleDeleteConfirmClick}
              />
              <OutlineBtn
                className="w-100 py-2"
                text={"Cancel"}
                handleOnClick={handleToggleDeleteModal}
              />
            </div>
          }
        />
      </Navbar>
      {showNotifications && (
        <NotificationTabs
          notifications={userNotifications}
          tabLabels={notificationTabLabels}
          toggleNotifications={toggleNotifications}
        />
      )}
    </>
  );
};

export default memo(Topbar);
