import React from "react";
import { useSelector } from "react-redux";
import functions from "../utils/functions";
import { Navigate } from "react-router-dom";

export function PublicRoute({ Component, props }) {
  const { user } = useSelector((state) => state.user);

  if (user) {
    return (
      <Navigate
        to={functions.getInitialDashboardRoute(user?.role)}
        replace={true}
      />
    );
  } else {
    return <Component {...props} />;
  }
}
