import { lazy } from "react";
import userRoutes from "./UserRoutes";
import adminRoutes from "./AdminRoutes";
import { userRole, adminRole } from "./routeConfig";

const routes = [
  {
    path: "/",
    component: lazy(() => import("../Pages/Auth/LandingPage")),
    isPublic: true,
    exact: true,
    role: [userRole, adminRole],
  },
  {
    path: "/signIn",
    component: lazy(() => import("../Pages/Auth/SignIn")),
    isPublic: true,
    exact: true,
    role: [userRole, adminRole],
  },
  {
    path: "/signUp",
    component: lazy(() => import("../Pages/Auth/SignUp")),
    isPublic: true,
    exact: true,
    role: [userRole, adminRole],
  },
  {
    path: "/otpVerification/:type",
    component: lazy(() => import("../Pages/Auth/OtpVerification")),
    isPublic: true,
    exact: true,
    role: [userRole, adminRole],
  },
  {
    path: "/pharmacyDetail",
    component: lazy(() => import("../Pages/Auth/PharmacyDetail")),
    isPublic: true,
    exact: true,
    role: [userRole, adminRole],
  },
  {
    path: "/forgotPassword",
    component: lazy(() => import("../Pages/Auth/ForgotPassword")),
    isPublic: true,
    exact: true,
    role: [userRole, adminRole],
  },
  {
    path: "/resetPassword",
    component: lazy(() => import("../Pages/Auth/ResetPassword")),
    isPublic: true,
    exact: true,
    role: [userRole, adminRole],
  },
]
  .concat(userRoutes)
  .concat(adminRoutes);

export default routes;
