let Images = {
  // =============================landingPageImgs===================================
  LOGO_IMG: require("../../Assets/Images/Common/logo.svg").default,
  LANDING_IMG: require("../../Assets/Images/Landing/LandingPageImg.png"),
  // ===============================SignInImgs======================================
  SIDE_PANEL_SIGNIN_IMG: require("../../Assets/Images/SignIn/SidePanelSignInImg.png"),
  // ===============================SignInImgs======================================
  SIDE_PANEL_SIGNUP_IMG: require("../../Assets/Images/SignUp/SidePanelSignUpImg.png"),
  // ===============================OtpImgs======================================
  SIDE_PANEL_OTP_IMG: require("../../Assets/Images/Otp/SidePanelOTPImg.png"),
  // ===============================PasswordImgs======================================
  SIDE_PANEL_PASSWORD_IMG: require("../../Assets/Images/Password/SidePanelPasswordImg.png"),
  // ===============================ProfileImgs=======================================
  USER_DUMMY_IMG: require("../../Assets/Images/Profile/userDummyImg.png"),
  EDIT_ICON_IMG: require("../../Assets/Images/Profile/editIcon.png"),


  // SMALL_LOGO_IMG: require("../../Assets/Images/smallLogo.svg").default,
  // ABOUT_US_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/aboutBgImg.png",
  // GOAL_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/OurGoal.png",
  // VISION_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/OurVision.png",
  // ABOUT_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/AboutFitnee.png",
  // DEFAULT_USER_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/defaultImg.svg",
  // FEATURE_ONE:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/FeatureOneImg.png",
  // FEATURE_TWO:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/FeatureTwoImg.png",
  // FEATURE_THREE:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/FeatureThreeImg.png",
  // FEATURE_FOUR:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/FeatureFourImg.png",
  // ARABIA_FLAG_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/ArabiaFlag.svg",
  // INSTA_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/Insta.svg",
  // TIKTOK_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/Ticktok.svg",
  // AMERICAN_FLAG_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/AmericanFlag.svg",
  // TWITTER_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/Twitter.svg",
  // WHATSAPP_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/Whatsapp.svg",
  // YOUTUBE_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/Youtube.svg",
  // HERO_TEXT_BACKGROUND_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/HeroTextBgImage.png",
  // HERO_BACKGROUND_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/HeroBackground.svg",
  // // =============================sliderImgs===================================
  // SLIDER1_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/profile1.svg",

  // //================================loginImgs==================================
  // LOGIN_BG_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/loginBgImg.svg",
  // EMAIL_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/emailLogo.svg",
  // PHONE_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/phoneLogo.svg",
  // PERSON_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/personLogo.svg",
  // //==============================registerAsImgs===============================
  // REGISTER_AS_BG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/RegisterAsBackground.svg",
  // //==============================GuestServiceProviderListImgs=================
  // PROFILE2_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/profile1.svg",
  // PROFILE3_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/profile1.svg",
  // PROFILE4_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/profile1.svg",
  // PROFILE5_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/profile1.svg",
  // ARROW_RIGHT_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/RightArrow.svg",
  // SEE_MORE_BG_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/SeeMoreBg.svg",
  // SHORTLOGO_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/DumbleLogo.svg",
  // //==============================GuestServiceProviderProviderImgs=============
  // COMMENT_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/TrainerImg.svg",
  // DOCUMENT_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/DocumentImg.svg",
  // FILTER_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/filterIcon.png",
  // //==============================SubscriptionImgs=============================
  // TRAINER_ONE_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/trainerOneMonth.svg",
  // TRAINER_TWO_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/trainerTwoMonth.svg",
  // TRAINER_THREE_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/trainerThreeMonth.svg",

  // NUTRITIONIST_ONE_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/nutritionistOneMonth.svg",
  // NUTRITIONIST_TWO_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/nutritionistTwoMonth.svg",
  // NUTRITIONIST_THREE_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/nutritionistThreeMonth.svg",

  // BOTH_T_AND_N_ONE_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/bothOneMonth.svg",
  // BOTH_T_AND_N_TWO_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/bothTwoMonth.svg",
  // BOTH_T_AND_N_THREE_MONTH_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/bothThreeMonth.svg",

  // EXERCISE_SUBSCRIPTION_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/exerciseFigure.svg",

  // VISA_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/visaIcon.png",
  // MADA_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/madaIcon.png",
  // STC_PAY_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/stcPayIcon.png",
  // APPLE_PAY_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/applePayIcon.png",
  // //==============================ConstImgs====================================
  // ARROW_DOWN_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/ArrowDown.svg",
  // CAMERA_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/Frame.svg",
  // ARROW_UP_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/ArrowUp.svg",
  // CREDIT_CARD_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/CreditCardImg.png",
  // PAYMENT_METHOD_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/PaymentMethod.png",
  // CARD_ICON_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/CardIcon.svg",
  // GREEN_CIRCLE_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/greenCircle.png",
  // YELLOW_CIRCLE_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/yellowCircle.png",
  // UPLOAD_BG_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/uploadImg.svg",
  // UPLOAD_ICON:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/uploadImg.svg",
  // PASSWORD_ICON_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/passwordIcon.svg",
  // USER_DUMMY_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/userDummyImg.png",
  // NO_DATA_FOUND_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/noDataFound.png",
  // //==============================Trainee Images====================================
  // FEATURE_BG_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/featureBg.svg",
  // //==============================appStore and Google btn Images====================================
  // APP_STORE_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/appStore.svg",
  // CVV_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/Hint.svg",
  // GOOGLE_PLAY_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/googlePlay.svg",

  // //==============================QR Code Img====================================
  // QR_CODE_IMG:
  //   "https://fitme-dev-bucket.s3.eu-north-1.amazonaws.com/media/webStatic/QRImage.svg",

  // //============================================loading============================

  // POSTER_LOADING_GIF: require("../../Assets/Images/WalkPoster.gif"),
};

export default Images;
