import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import Topbar from "../../../Shared/Topbar";
import functions from "../../../utils/functions";
import React, { useCallback, useState } from "react";
import UserSidebar from "../../../Shared/UserSidebar";
import AdminSidebar from "../../../Shared/AdminSidebar";

const GeneralLayout = (props) => {
  const { user } = useSelector((state) => state.user);
  const [toggleSidebar, setToggleSidebar] = useState(true);

  const setToogle = useCallback((value) => {
    setToggleSidebar(value);
  }, []);

  return (
    <React.Fragment>
      {user && (
        <>
          {functions.isAdmin(user?.role) && (
            <AdminSidebar
              toggleSidebar={toggleSidebar}
              setToggleSidebar={setToogle}
            />
          )}
          {functions.isUser(user?.role) && (
            <UserSidebar
              toggleSidebar={toggleSidebar}
              setToggleSidebar={setToogle}
            />
          )}
          <div
            className={`transition vh-100 overflow-y-scroll ${
              toggleSidebar && window.innerWidth > 576 ? "openSidebar" : ""
            }`}
          >
            <div>
              <Topbar
                toggleSidebar={toggleSidebar}
                setToggleSidebar={setToogle}
              />
            </div>

            <Container fluid>{props.children}</Container>
          </div>
        </>
      )}
      {user === null && <Container fluid>{props.children}</Container>}
    </React.Fragment>
  );
};

export default GeneralLayout;
