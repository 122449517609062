import { lazy } from "react";
import { userRole } from "./routeConfig";

const userRoutes = [
  {
    path: "/user/profile",
    component: lazy(() => import("../Pages/User/Profile")),
    isPublic: false,
    exact: true,
    role: [userRole],
  },
  {
    path: "/user/changePassword",
    component: lazy(() => import("../Pages/User/ChangePassword")),
    isPublic: false,
    exact: true,
    role: [userRole],
  },
  {
    path: "/user/buyer/wishlist",
    component: lazy(() => import("../Pages/User/Buyer/Wishlist")),
    isPublic: false,
    exact: true,
    role: [userRole],
  },
  {
    path: "/user/seller/wishlist",
    component: lazy(() => import("../Pages/User/Seller/Wishlist")),
    isPublic: false,
    exact: true,
    role: [userRole],
  },
  {
    path: "/user/buyer/potentialBuys/:buyerWishlistId",
    component: lazy(() => import("../Pages/User/Buyer/PotentialBuy")),
    isPublic: false,
    exact: true,
    role: [userRole],
  },
  {
    path: "/user/seller/incomingSale",
    component: lazy(() => import("../Pages/User/Seller/IncomingSale")),
    isPublic: false,
    exact: true,
    role: [userRole],
  },
  {
    path: "/user/seller/order",
    component: lazy(() => import("../Pages/User/Seller/Order")),
    isPublic: false,
    exact: true,
    role: [userRole],
  },
  {
    path: "/user/buyer/order",
    component: lazy(() => import("../Pages/User/Buyer/Order")),
    isPublic: false,
    exact: true,
    role: [userRole],
  },
];

export default userRoutes;
