import { createSlice } from "@reduxjs/toolkit";
import {
  addNdc,
  getWishList,
  cancelOrder,
  getSellerOrders,
  changeOrderStatus,
  addShipmentDetail,
  markWishlistAsCompleted,
  getStripeConnectAccountStatus,
  createStripeAccountAndVerifyLink,
} from "./sellerApi";

export const sellerSlice = createSlice({
  name: "seller",
  initialState: {
    error: null,
    loading: "idle",
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNdc.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addNdc.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(addNdc.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getWishList.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getWishList.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getWishList.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(markWishlistAsCompleted.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(markWishlistAsCompleted.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(markWishlistAsCompleted.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getSellerOrders.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getSellerOrders.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getSellerOrders.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(changeOrderStatus.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(changeOrderStatus.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(changeOrderStatus.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(cancelOrder.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(cancelOrder.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(cancelOrder.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(addShipmentDetail.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addShipmentDetail.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(addShipmentDetail.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getStripeConnectAccountStatus.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getStripeConnectAccountStatus.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getStripeConnectAccountStatus.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(createStripeAccountAndVerifyLink.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(createStripeAccountAndVerifyLink.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(createStripeAccountAndVerifyLink.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default sellerSlice.reducer;
