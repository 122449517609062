import { lazy } from "react";
import { adminRole } from "./routeConfig";

const adminRoutes = [
  {
    path: "/admin/profile",
    component: lazy(() => import("../Pages/Admin/Profile")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/admin/changePassword",
    component: lazy(() => import("../Pages/Admin/ChangePassword")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "admin/userProfile/:userId",
    component: lazy(() => import("../Pages/Admin/User/UserProfile")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/admin/commission",
    component: lazy(() => import("../Pages/Admin/Commission")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/admin/commissionRecord",
    component: lazy(() => import("../Pages/Admin/CommissionRecord")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/admin/buyer/wishlist",
    component: lazy(() => import("../Pages/Admin/Buyer/Wishlist")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/admin/seller/wishlist",
    component: lazy(() => import("../Pages/Admin/Seller/Wishlist")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/admin/userManagement",
    component: lazy(() => import("../Pages/Admin/User/UserManagement")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/admin/matchingWishlist/:wishlistId",
    component: lazy(() => import("../Pages/Admin/MatchingWishlist")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/adminView/userProfile/:userId",
    component: lazy(() => import("../Pages/Admin/User/UserProfile")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
  {
    path: "/admin/order",
    component: lazy(() => import("../Pages/Admin/Order")),
    isPublic: false,
    exact: true,
    role: [adminRole],
  },
];

export default adminRoutes;
