import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { PUBLIC_INITIAL_ROUTE } from "../utils/constants";

export function PrivateRoute({ Component, role, props }) {
  const { user } = useSelector((state) => state.user);

  if (user === null) {
    return <Navigate to={PUBLIC_INITIAL_ROUTE} />;
  } else {
    return <Component {...props} />;
  }
}
