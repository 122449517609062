import "./App.scss";
import "./firebase.js";
import {
  PAYMENT_METHOD_STATUS,
  GET_USER_NOTIFICATIONS_URL,
  ADD_PAYMENT_METHOD_CHECKOUT_SESSION_URL,
} from "./utils/constants.js";
import routes from "./Routes/AllRoutes";
import "react-date-range/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-date-range/dist/theme/default.css";
import FillBtn from "./Shared/Buttons/FillBtn.jsx";
import LoadingScreen from "./Shared/LoadingScreen";
import { PublicRoute } from "./Routes/PublicRoutes";
import { PrivateRoute } from "./Routes/PrivateRoutes";
import { useDispatch, useSelector } from "react-redux";
import GeneralLayout from "./Pages/Layout/GeneralLayout";
import OutlineBtn from "./Shared/Buttons/OutlineBtn.jsx";
import { setFcmToken } from "./Redux/features/User/userSlice.js";
import { getNotificationToken, onMessageListener } from "./firebase.js";
import {
  getPaymentMethodCheckoutSessionUrl,
  getUserNotifications,
} from "./Redux/features/User/userApi.js";
import NotificationToaster from "./Shared/NotificationToaster/index.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import InformationModal from "./Shared/Modals/InformationModal/index.jsx";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import functions from "./utils/functions.js";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [notification, setNotification] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(
    false
  );

  const fetchUserNotifications = () => {
    setTimeout(() => {
      if (user) {
        const data = {
          apiEndpoint: GET_USER_NOTIFICATIONS_URL,
        };

        dispatch(getUserNotifications(data));
      }
    }, 500);
  };

  useEffect(() => {
    setToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.paymentMethodStatus === PAYMENT_METHOD_STATUS.NEW) {
      if (!functions.isAdmin(user?.role)) {
        setShowAddPaymentMethodModal(true);
      }
    }
    if (user?.paymentMethodStatus === PAYMENT_METHOD_STATUS.PENDING) {
      setShowAddPaymentMethodModal(false);
    }
  }, [user]);

  const setToken = async () => {
    const firebaseToken = await getNotificationToken();
    if (firebaseToken) {
      dispatch(setFcmToken(firebaseToken));
    }
  };

  const handleToggleAddPaymentModal = useCallback(() => {
    setShowAddPaymentMethodModal(!showAddPaymentMethodModal);
  }, [showAddPaymentMethodModal]);

  const handleAddPaymentMethodConfirmClick = useCallback(() => {
    const data = {
      apiEndpoint: ADD_PAYMENT_METHOD_CHECKOUT_SESSION_URL,
    };

    dispatch(getPaymentMethodCheckoutSessionUrl(data)).then((res) => {
      if (res.type === "getPaymentMethodCheckoutSessionUrl/fulfilled") {
        window.location.href = res?.payload?.url;
      }
    });
  }, [dispatch]);

  onMessageListener()
    .then((payload) => {
      const body = payload.notification.body;
      const title = payload.notification.title;
      setNotification({
        title,
        body,
      });
      fetchUserNotifications();
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <React.Fragment>
      <ToastContainer />
      <NotificationToaster
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        notificationData={notification}
      />
      <Router>
        <Routes>
          {routes.map((route, index) => {
            const Component = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  <>
                    <GeneralLayout
                      isPublic={route.isPublic}
                      isGuest={route.isGuest}
                      isPrivate={route.isPrivate}
                      isAuth={route.isAuth}
                      theme={route.theme ?? "black"}
                    >
                      <Suspense fallback={<LoadingScreen />}>
                        {!route.isPublic ? (
                          <PrivateRoute
                            props={route}
                            role={route?.role}
                            Component={Component}
                          />
                        ) : (
                          <PublicRoute
                            props={route}
                            role={route?.role}
                            Component={Component}
                          />
                        )}
                      </Suspense>
                    </GeneralLayout>
                  </>
                }
              />
            );
          })}
        </Routes>
      </Router>

      <InformationModal
        isOpen={showAddPaymentMethodModal}
        content={
          <p className="mb-0">
            {"Please connect your bank to stripe for future payments!"}
          </p>
        }
        footer={
          <div className="d-flex justify-content-between w-100 gap-2">
            <FillBtn
              className="w-100 py-2"
              text={"Confirm"}
              handleOnClick={handleAddPaymentMethodConfirmClick}
            />
            <OutlineBtn
              className="w-100 py-2"
              text={"Cancel"}
              handleOnClick={handleToggleAddPaymentModal}
            />
          </div>
        }
      />
    </React.Fragment>
  );
}

export default App;
