import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUsers,
  getAllOrders,
  getCommission,
  changeUserStatus,
  getWishlistMatching,
  getAdminUserProfile,
  getCommissionRecords,
  updateCommissionValue,
  changeUserCommissionValue,
  sendMatchedWishlistToBuyer,
} from "./adminApi";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    error: null,
    loading: "idle",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllUsers.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(changeUserStatus.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(changeUserStatus.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(changeUserStatus.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getWishlistMatching.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getWishlistMatching.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getWishlistMatching.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(sendMatchedWishlistToBuyer.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(sendMatchedWishlistToBuyer.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(sendMatchedWishlistToBuyer.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getAdminUserProfile.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAdminUserProfile.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getAdminUserProfile.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getCommission.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCommission.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getCommission.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(updateCommissionValue.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateCommissionValue.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(updateCommissionValue.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getCommissionRecords.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCommissionRecords.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getCommissionRecords.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(changeUserCommissionValue.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(changeUserCommissionValue.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(changeUserCommissionValue.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getAllOrders.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllOrders.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(getAllOrders.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default adminSlice.reducer;
